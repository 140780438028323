pre.highlight,
.highlight pre {
  max-height: 30rem;
  margin: 1.5rem 0;
  overflow: auto;
  border-radius: var(--tblr-border-radius); }
  pre.highlight::-webkit-scrollbar,
  .highlight pre::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    -webkit-transition: background 0.3s;
    transition: background 0.3s; }
    @media (prefers-reduced-motion: reduce) {
      pre.highlight::-webkit-scrollbar,
      .highlight pre::-webkit-scrollbar {
        -webkit-transition: none;
        transition: none; } }
  pre.highlight::-webkit-scrollbar-thumb,
  .highlight pre::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(var(--tblr-body-color-rgb), 0.16); }
  pre.highlight::-webkit-scrollbar-track,
  .highlight pre::-webkit-scrollbar-track {
    background: rgba(var(--tblr-body-color-rgb), 0.06); }
  pre.highlight:hover::-webkit-scrollbar-thumb,
  .highlight pre:hover::-webkit-scrollbar-thumb {
    background: rgba(var(--tblr-body-color-rgb), 0.32); }
  pre.highlight::-webkit-scrollbar-corner,
  .highlight pre::-webkit-scrollbar-corner {
    background: transparent; }

.highlight {
  margin: 0; }
  .highlight code > * {
    margin: 0 !important;
    padding: 0 !important; }
  .highlight .dl {
    color: #4070a0; }
  .highlight .c {
    color: #727272; }
  .highlight .c1 {
    color: #727272; }
  .highlight .ch {
    font-style: italic;
    color: #60a0b0; }
  .highlight .cm {
    color: #727272; }
  .highlight .cp {
    color: #008085; }
  .highlight .cpf {
    color: #007020; }
  .highlight .cs {
    color: #727272; }
  .highlight .gd {
    background-color: #fcc;
    border: 1px solid #c00; }
  .highlight .ge {
    font-style: italic; }
  .highlight .gh {
    color: #030; }
  .highlight .gi {
    background-color: #cfc;
    border: 1px solid #0c0; }
  .highlight .gl {
    text-decoration: underline; }
  .highlight .go {
    color: #aaa; }
  .highlight .gp {
    color: #009; }
  .highlight .gr {
    color: #f00; }
  .highlight .gs {
    font-weight: 700; }
  .highlight .gt {
    color: #9c6; }
  .highlight .gu {
    color: #030; }
  .highlight .il {
    color: #c24f19; }
  .highlight .k {
    color: #069; }
  .highlight .kc {
    color: #069; }
  .highlight .kd {
    color: #069; }
  .highlight .kn {
    color: #069; }
  .highlight .kp {
    color: #069; }
  .highlight .kr {
    color: #069; }
  .highlight .kt {
    color: #078; }
  .highlight .m {
    color: #c24f19; }
  .highlight .mf {
    color: #c24f19; }
  .highlight .mb {
    color: #40a070; }
  .highlight .mh {
    color: #c24f19; }
  .highlight .mi {
    color: #c24f19; }
  .highlight .mo {
    color: #c24f19; }
  .highlight .na {
    color: #006ee0; }
  .highlight .nb {
    color: #366; }
  .highlight .nc {
    color: #168174; }
  .highlight .nd {
    color: #6b62de; }
  .highlight .ne {
    color: #c00; }
  .highlight .nf {
    color: #b715f4; }
  .highlight .ni {
    color: #727272; }
  .highlight .nl {
    color: #6b62de; }
  .highlight .nn {
    color: #007ca5; }
  .highlight .no {
    color: #360; }
  .highlight .nt {
    color: #2f6f9f; }
  .highlight .nv {
    color: #033; }
  .highlight .o {
    color: #555; }
  .highlight .ow {
    color: #000; }
  .highlight .s {
    color: #d73038; }
  .highlight .s1 {
    color: #c30; }
  .highlight .s2 {
    color: #c30; }
  .highlight .sa {
    color: #4070a0; }
  .highlight .sb {
    color: #c30; }
  .highlight .sc {
    color: #c30; }
  .highlight .sd {
    font-style: italic;
    color: #c30; }
  .highlight .se {
    color: #c30; }
  .highlight .sh {
    color: #c30; }
  .highlight .si {
    color: #a00; }
  .highlight .sr {
    color: #337e7e; }
  .highlight .ss {
    color: #fc3; }
  .highlight .sx {
    color: #c30; }
  .highlight .w {
    color: #bbb; }
  .highlight .language-bash::before, .highlight .language-sh::before {
    color: #009;
    content: "$ ";
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .highlight .language-bash .m, .highlight .language-sh .m {
    color: inherit; }
  .highlight .language-powershell::before {
    color: #009;
    content: "PM> ";
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }

.example {
  padding: 2rem;
  margin: 1rem 0 2rem;
  border: 1px solid #e4e5e7;
  border-radius: 3px 3px 0 0;
  position: relative;
  min-height: 12rem;
  display: flex;
  align-items: center;
  overflow-x: auto; }

.example-centered {
  justify-content: center; }
  .example-centered .example-content {
    flex: 0 auto; }

.example-content {
  font-size: 0.875rem;
  line-height: 1.4285714;
  color: #0f172a;
  flex: 1;
  max-width: 100%; }
  .example-content .page-header {
    margin-bottom: 0; }

.example-bg {
  background: #f1f5f9; }

.example-code {
  margin: 2rem 0;
  border: 1px solid #e4e5e7;
  border-top: none; }
  .example-code pre {
    margin: 0;
    border: 0;
    border-radius: 0 0 3px 3px; }
  .example + .example-code {
    margin-top: -2rem; }

.example-column {
  margin: 0 auto; }
  .example-column > .card:last-of-type {
    margin-bottom: 0; }

.example-column-1 {
  max-width: 26rem; }

.example-column-2 {
  max-width: 52rem; }

.example-modal-backdrop {
  background: #0f172a;
  opacity: 0.24;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  border-radius: 2px 2px 0 0; }

@media not print {
  .theme-dark .example {
    background-color: #0c1322;
    border-color: #1a2748; }
  .theme-dark .example-content {
    color: #f8fafc; }
  .theme-dark .example-code {
    border-color: #1a2748;
    border-top: none; } }

.card-sponsor {
  background: #dbe7f6 no-repeat center/100% 100%;
  border-color: #548ed2;
  min-height: 316px; }

body.no-transitions * {
  transition: none !important; }

.dropdown-menu-demo {
  display: inline-block;
  width: 100%;
  position: relative;
  top: 0;
  margin-bottom: 1rem !important; }

.demo-icon-preview {
  position: -webkit-sticky;
  position: sticky;
  top: 0; }
  .demo-icon-preview svg,
  .demo-icon-preview i {
    width: 15rem;
    height: 15rem;
    font-size: 15rem;
    stroke-width: 1.5;
    margin: 0 auto;
    display: block; }
    @media (max-width: 575.98px) {
      .demo-icon-preview svg,
      .demo-icon-preview i {
        width: 10rem;
        height: 10rem;
        font-size: 10rem; } }

.demo-icon-preview-icon pre {
  margin: 0;
  -webkit-user-select: all;
     -moz-user-select: all;
          user-select: all; }

.demo-dividers > p {
  opacity: .2;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.demo-icons-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 -2px -1px 0;
  list-style: none; }
  .demo-icons-list > * {
    flex: 1 0 4rem; }

.demo-icons-list-wrap {
  overflow: hidden; }

.demo-icons-list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
  text-align: center;
  padding: .5rem;
  border-right: 1px solid var(--tblr-border-color);
  border-bottom: 1px solid var(--tblr-border-color);
  color: inherit;
  cursor: pointer; }
  .demo-icons-list-item .icon {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1.5rem; }
  .demo-icons-list-item:hover {
    text-decoration: none; }

.settings-btn {
  position: fixed;
  right: -1px;
  top: 10rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: rgba(15, 23, 42, 0.04) 0 2px 4px 0; }

.settings-scheme {
  display: inline-block;
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  position: relative;
  border: 1px solid var(--tblr-border-color);
  box-shadow: rgba(15, 23, 42, 0.04) 0 2px 4px 0; }
  .settings-scheme-light {
    background: linear-gradient(135deg, #ffffff 50%, #f8fafc 50%); }
  .settings-scheme-mixed {
    background-image: linear-gradient(135deg, #0f172a 50%, #fff 50%); }
  .settings-scheme-transparent {
    background: #f8fafc; }
  .settings-scheme-dark {
    background: #0f172a; }
  .settings-scheme-colored {
    background-image: linear-gradient(135deg, var(--tblr-primary) 50%, #f8fafc 50%); }