body {font-family: "Inter Regular","TwemojiMozilla-Regular";overflow: auto;}
.wrapper {float: left;width: 100%;overflow: hidden;}
a {
    cursor: pointer;
}
/* login css start here */
.loginform_wrapper {height: 100vh;background-image: url("../img/qr_backgroud.png");background-position: center;}
.loginform_wrapper .card-body {padding: 37px 35px;display: table;}
.loginform_wrapper .card-body .logowraper {float: left;width: 100%;text-align: center;}
.loginform_wrapper .card-body .logowraper img {width: 80px;height: 80px;object-fit: contain;object-position: center;}
.loginform_wrapper .card-body .card-title {float: left;width: 100%;margin: 2px 0px 25px !important;position: relative;font-size: 22px;font-family: 'Inter Bold';}
.loginformstart {float: left;width: 100%;position: relative;}
.loginformstart .mb-3 {float: left;width: 100%;}
.loginformstart .form-label {font-size: 14px;letter-spacing: 0.3px;}
.loginformstart .react-tel-input .form-control {height: 44px;width: 100%;font-size: 13px;box-shadow: none;border-radius: 0px;}
.loginformstart .form-footer {float: left;width: 100%;margin: 10px 0px 0px;}
.loginformstart .form-footer button {padding: 12px 10px;border-radius: 0px;}
.loginformstart .otp_wrapper input {height: 41px !important;font-size: 13px;box-shadow: none;border-radius: 0px;width: 43px !important;border: 1px solid #ccc;outline: none !important;margin-right: 0px !important;}
.loginformstart .otp_wrapper div:nth-child(2) {display: flex;justify-content: space-between;margin: 0px 0px 8px;position: relative;}
.loginformstart .otp_wrapper div:nth-child(3) {display: flex;justify-content: space-between;vertical-align: middle;align-items: center;}
.loginformstart .otp_wrapper div:nth-child(3) button {padding: 0px;font-size: 12px;background: transparent;border: 0px;letter-spacing: 0.3px;}
.login-form {box-shadow: 0 3px 91px #00000029;background-color: #fff;border: 0px;border-radius: 0px;}
.loginformstart.loginformstartotp .form-footer {margin: 0px 0px 0px !important;}
.loginformstart.loginformstartotp{width: 80%;float: none;margin: 0 auto;display: table;}

.selectNoInputField {
    float: left;
    width: 100%;
    border: 1px solid #cacaca;
    position: relative;
    display: flex;
}
.selectNoInputField input {
    height: 44px;
    border: 0px;
    padding: 10px 10px;
    font-size: 13px;
    outline: none;
    width: 100%;
}
.selectNoInputField .inputBoxSelect {
    width: 55px;
    text-align: center;
    background-color: #f5f5f5;
    border-right: 1px solid #cacaca;
    margin: 0px;
}
.flagsDropdown {
    float: left;
    width: 100%;
    max-height: 200px;
    overflow: auto;
    margin: 0px;
    padding: 0px;
    position: absolute;
    z-index: 9;
    background-color: #fff;
    box-shadow: 1px 2px 10px rgb(0 0 0 / 35%);
    left: 0;
    bottom: auto;
    top: 45px;
}
.flagsDropdown li {
    float: left;
    width: 100%;
    list-style: none;
    display: flex;
    padding: 10px 10px;
    font-size: 13px;
    cursor: pointer;
}
.flagsDropdown li:hover {
    background-color: #eee;
}
.flagsDropdown li img {
    width: 30px;
    margin: 0px 8px 0px 0px;
}
.selectNoInputField .inputBoxSelect .selectEmojis {
    padding: 1px;
    border: 0px;
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    text-align: center;
    justify-content: center;
}
.selectNoInputField .inputBoxSelect .selectEmojis .flagsimg {
    width: 24px;
    margin: 0px 7px 0px 0px;
}
.selectNoInputField .inputBoxSelect .selectEmojis .arrowdownimg {
    width: 6px;
    margin: 0px 0px 0px 3px;
    position: absolute;
    right: 5px;
}
/* login css end here */

/* header css start here */
.headerDashboard {float: left;width: 100%;position: fixed;top: 0;left: 0;z-index: 999;box-shadow: 0px -3px 13px 12px #eff1f9;background: rgba(255,255,255,0.8);}
.headerDashboard .navbar-nav .nav-item::after {border: 1px solid #014087 !important;}
.headerDashboard .navbar-nav .nav-item .nav-link {border-radius: 9px;font-size: 13px;}
.headerDashboard .navbar-nav .nav-item .nav-link .nav-link-icon img {width: 17px;height: 17px;max-width: 17px;}
.headerDashboard .navbar-nav .nav-item.active .nav-link {color: #014087;}
.headerDashboard .navbar-nav .nav-item.active .nav-link .nav-link-icon {color: #014087;}
.headerDashboard .dropdown .admintitle {font-family: 'Inter Light BETA';color: #808080 !important;font-weight: 100;letter-spacing: 0.3px;}
/* header css end here */

/* body css start here */
.dashboardmain {float: left;width: 100%;display: block;}
.dashboardmain .page-wrapper {float: left;width: 100%;position: relative;}
.dashboardmain .page-wrapper .page-body {min-height: calc(100vh - 50px);background-color: #EFF1F9;margin: 0px;padding: 75px 0px 15px;position: relative;float: left;width: 100%;}
.dashboardmain .page-wrapper .page-body .card-table table thead th {color: #333;padding: 15px 10px;text-transform: capitalize;font-size: 12px;background-color: #eee;}
.dashboardmain .page-wrapper .page-body .card-table table.notificationstable thead th:last-child {padding: 15px 20px;} 
.dashboardmain .page-wrapper .page-body .card-table table thead th:first-child {padding-left: 1.5rem;}
.dashboardmain .page-wrapper .page-body .card-header .card-title {font-family: 'Inter Medium';float: left;}
.dashboardmain .page-wrapper .page-body .card-header .cradRightsection {float: right;width: auto;position: absolute;right: 24px;top: 14px;}
.dashboardmain .page-wrapper .page-body .card-header {width: 100%;display: inline-block;}
.dashboardmain .page-wrapper .page-body .card-header .cradRightsection select {padding: 5px 17px 5px 9px;margin: 0px;font-size: 12px;appearance: none;border-radius: 0px;border: 1px solid #cdcdcd;outline: none;background-image: url("../img/caret-down.png");background-repeat: no-repeat;background-size: 8px;background-position: 97% 11px;cursor: pointer;}
.dashboardmain .page-wrapper .page-body .card .card-table {float: left;width: 100%;padding: 0px 23px 23px;}
.dashboardmain .page-wrapper .page-body .card .card-table table {border: 1px solid #eee;}
.dashboardmain .page-wrapper .page-body .card {background-color: #fff;border: 0px !important;}
.container-xl {padding: 0px 18px;}
.card.card_blogsset .card-body {padding: 19px 17px;overflow: hidden;}
.row-deck>.col .card, .row-deck>[class*=col-] .card {border-radius: 10px;}
.paginationCustom {float: left;width: 100%;padding: 0px;margin: 15px 0px 0px;}
.paginationCustom li:first-child, .paginationCustom li:last-child {display: none;}
.paginationCustom li .rpb-item--active {background-color: #014087;}
.paginationCustom li button {width: 29px;height: 29px;min-width: 29px;font-size: 14px;}
.notpermission {background-color: #eee;padding: 10px;float: left;width: 100%;font-size: 13px;margin: 15px 0 0;line-height: 18px;}
.permissionIcon {width: 22px !important;max-width: fit-content;}
.notpermission img {width: 20px;}
.commonpopup.addUserWrapper .commonpopupBox {max-height: 340px;overflow: auto;padding: 20px 10px 20px 20px;}
.commonpopup.addUserWrapperPermission .commonpopupBox {padding: 20px 10px 20px 20px;}
.commonpopup.addUserWrapper .commonpopupBox .form-group {margin-bottom: 10px;}
.commonpopup.addUserWrapper .commonpopupBox .form-group label {
    float: left;
    width: 100%;
    margin-bottom: 4px;
}
.commonpopup.addUserWrapperPermission {
    transform: translate(-50%, -45%);
}
.commonpopup.addUserWrapper .commonpopupBox .form-group .input, .commonpopup.addUserWrapper .commonpopupBox .form-group .inputBoxs {
    border-radius: 0px;
    height: 40px;
    font-size: 13px;
}

.commonpopup.addUserWrapper {transform: translate(-50%, -40%);}
.checkbox_wraps {
    float: left;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 15px 0 !important;
}
.checkbox_wraps .form-group {
    width: 50% !important;
    margin: 0 0 6px;
}
.adduserblog {
    float: left;
    width: 100%;
}
.adduserblog .form-group {
    float: left;
    width: 100%;
    margin-bottom: 6px;
}
#multiselectContainerReact .search-wrapper.searchWrapper {min-height: 40px;overflow: auto;}
table .imagepreviewBox {width: 50px;height: 50px;display: block;box-sizing: border-box;background-color: #f1f5f9;position: relative;float: left;}
.headerDashboard .imagepreviewBox {width: 33px;height: 33px;border-radius: 100%;display: block;box-sizing: border-box;background-color: #f1f5f9;position: relative;float: left;overflow: hidden;}
.headerDashboard .imagepreviewBox .img-thumbnail, table .imagepreviewBox .img-thumbnail {padding: 0px;height: 100%;width: 100%;object-fit: cover;object-position: center;}
.card_blogsset .Dashboardblosgswr {float: left;width: 100%;position: relative;display: flex;justify-content: space-between;z-index: 9;}
.card_blogsset .Dashboardblosgswr h1 {float: left;width: 100%;font-size: 27px;font-family: 'Inter Bold';margin: 5px 0px 5px !important;}
.card_blogsset .Dashboardblosgswr h4 {float: left;width: 100%;margin: 0px 0px 4px;font-family: 'Inter Semi Bold';font-size: 15px;}
.card_blogsset .Dashboardblosgswr .subheader {font-size: 11px;font-weight: 100;text-transform: capitalize;font-family: 'Inter Light BETA';color: #333;margin: 2px 0 0;position: relative;display: inline-block;}
.progressbar {float: left;width: 100%;position: relative;z-index: 9;margin: 5px 0 0;}
.card_blogsset .Dashboardblosgswr .dashblogtext {float: left;width: auto;}
.card_blogsset .Dashboardblosgswr .dashbrImg {float: right;width: 55px;height: 55px;padding: 6px 6px;border-radius: 100%;display: flex;justify-content: center;align-items: center;}
.card_blogsset .Dashboardblosgswr .dashbrImg img {width: 41px;filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(79deg) brightness(102%) contrast(102%);}
.card_blogsset .Dashboardblosgswr .dashbrImg.groupchat img, .card_blogsset .Dashboardblosgswr .dashbrImg.userchat img {width: 36px;}
.card.card_blogsset .card-body::before{content: "";float: left;width: 270px;height: 270px;position: absolute;left: -80px;top: 39px;border-radius: 100%;opacity: 0.1;}
.card.card_blogsset .card-body.totlUserBlog::before {background-color: rgb(77, 76, 172);}
.card.card_blogsset .card-body.totlGroupBlog::before {background-color: rgb(255, 128, 139);}
.card.card_blogsset .card-body.UseChatBlog::before {background-color: rgb(56, 159, 225);}
.card.card_blogsset .card-body.GroupChatBlog::before {background-color: rgb(250, 142, 51);}
.cardblogwrappr {float: left;width: 100%;padding: 0px 23px 23px;position: relative;min-height: calc(100vh - 212px);}
.searchBoxWrapper {float: left;width: 100%;margin: 0 0 12px;position: relative;}
.coomforFiled {float: left;width: 100%;position: relative;}
.coomforFiled .form-select {padding: 7px 19px 7px 12px;margin: 0px;font-size: 13px;appearance: none;border-radius: 0px;height: 35px;border: 1px solid #cdcdcd;outline: none;background-image: url("../img/caret-down.png");background-repeat: no-repeat;background-size: 9px;background-position: 95% 14px;cursor: pointer;line-height: normal;box-shadow: none !important;}
.coomforFiled .form-input {padding: 7px 19px 7px 12px;margin: 0px;font-size: 13px;appearance: none;border-radius: 0px;height: 35px;border: 1px solid #cdcdcd;outline: none;cursor: pointer;line-height: normal;box-shadow: none !important;width: 100%;}
.formSearchBox {float: left;width: 100%;position: relative;}
.searchlistWrapperlist {float: left;width: 100%;position: absolute;top: auto;bottom: auto;background-color: #fff;box-shadow: -3px 1px 17px -6px #ccc;border: 1px solid #eee;z-index: 9999;}
.searchlistWrapperlist ul {float: left;width: 100%;padding: 0px;margin: 0px;max-height: 397px;overflow: auto;}
.sendMessageBtn {background-color: #014087;border-radius: 0px;}
.sendMessageBtn .loadersendbtns {border: 3px solid #fff;border-radius: 50%;border-top: 3px solid #014087;width: 18px;height: 18px;animation: spin 1s linear infinite;margin-right: 8px;}
.chatnotiftbtn .btn {padding: 2px 0px 14px 29px;border-radius: 0px;margin-right: 23px;font-size: 14px;position: relative;border: 0px;background: transparent;color: #626262;}
.chatnotiftbtn .btn.active {color: #014087;}
.chatnotiftbtn .btn:focus{background: none;}
.chatnotiftbtn .btn.active::after {content: "";float: left;width: 100%;height: 2px;color: #fff;background-color: #014087;position: absolute;left: 0;bottom: -1px;}
.commonsbtn .loadersendbtns {float: left;border: 3px solid #fff;border-radius: 50%;border-top: 3px solid #014087;width: 15px;height: 15px;animation: spin 1s linear infinite;margin-right: 8px;}
/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.searchlistWrapperlist ul li {float: left;width: 100%;border: 1px solid #eee;padding: 10px 10px;position: relative;cursor: pointer;list-style: none;}
.closechipsuser {position: absolute;width: 15px;right: -5px;top: 9px;background-color: #fff;border-radius: 100%;}
.userSelcchips .imagepreviewBox {margin: 0px 7px 0px 0px;}
.searchlistWrapperlist ul li .imagepreviewBox {float: left;width: 34px;height: 34px;margin: 0px 10px 0px 0px;position: relative;}
.searchboxbtn {background: transparent;border: 0px;width: 40px;position: absolute;right: 0px;top: 0;height: 100%;padding: 4px 5px;display: flex;justify-content: center;align-items: center;}
.searchboxbtn img {width: 18px;}
.searchlistWrapperlist ul li .imagepreviewBox .img-thumbnail {padding: 2px;width: 100%;height: 100%;border-radius: 100%;object-fit: cover;object-position: center;}
.searchlistWrapperlist ul li .userdetails {float: left;width: auto;}
.searchlistWrapperlist ul li .userdetails h4 {float: left;width: 100%;font-size: 13px;font-family: "Inter Semi Bold", "TwemojiMozilla-Regular";line-height: normal;margin: 1px 0px 2px;letter-spacing: 0.6px;}
.searchlistWrapperlist ul li .userdetails span {float: left;width: 100%;font-size: 11px;color: #757575;letter-spacing: 0.6px;}
.searchlistWrapperlist ul li:hover {background-color: #f5f5f5;}
.seletduser {float: right;width: auto;padding: 5px 13px;background-color: #04AA6D;color: #fff;font-size: 11px;border-radius: 20px;margin: 4px 0px 0px 0px;}
.selectuserwrapper {float: left;width: 100%;position: relative;margin: 15px 0px 5px;}
.userSelcchips {float: left;width: auto;display: flex;position: relative;background-color: #eee;border-radius: 30px;padding: 5px 15px 5px 8px;margin: 0px 14px 10px 0px;cursor: pointer;}
.imagepreviewBox {float: left;width: 25px;border-radius: 100%;overflow: hidden;height: 25px;object-fit: cover;object-position: center;position: relative;}
.messagewrappser {float: left;width: 100%;position: relative;}
.messagewrappser textarea {float: left;width: 100%;min-height: 140px;padding: 7px 19px 7px 12px;font-size: 13px;appearance: none;border-radius: 0px;border: 1px solid #cdcdcd;outline: none;line-height: normal;box-shadow: none !important;}
.imagepreviewBox img {padding: 0px;border: 0px;border-radius: 0px;width: 100%;height: 100%;word-break: break-all;}
.userSelcchips .userdetails {float: left;width: auto;font-size: 12px;margin: 3px 0px 0px;}
.form-filter {padding: 5px 26px 5px 9px;margin: 0px;font-size: 12px;border-radius: 0px;border: 1px solid #cdcdcd;outline: none;background-image: url("../img/searchBox.png");background-size: 14px;background-position: 96% center;background-repeat: no-repeat;}
/* body css end here */
.error_field {
    float: left;
    width: 100%;
    color: red;
    font-size: 13px;
    margin: 5px 0 0;
}
/* common popup css start here */
/* .popupConfirmationwrapper .modal-content .modal-footer #closepopup {margin: 0 0 12px;} */
.popupConfirmationwrapper .modal-content .modal-body h3 {font-family: 'Inter Semi Bold';font-size: 18px;}
/* common popup css end here */

/* footer section start here */
footer.footer.footer_section {float: left;width: 100%;padding: 15px 10px;font-size: 13px;position: relative;}
/* footer section end here */

/* firefox chat textbox css start here */
@-moz-document url-prefix() {
	.docfilesPreviewWrapper .Tooltip-Wrapper { right: 177px !important; top: 16px !important; } 
	.messageFieldWraps .messagField br { display: none; } 
	.messageFieldWraps .messagField div { line-height: 24px; max-height: 24px; height: 24px; } 
	/* .messageFieldWraps .messagField div::after { display: none !important; }  */
	.messageFieldWraps .messagField.fireBoxCursorNode div::after { display: none !important; } 
	.messageFieldWraps .messagField div:last-child::after { content: ""; width: 1px; height: 16.5px; background: #000; display: inline-block; animation: cursor-blink 1.5s steps(2) infinite; position: relative; top: 4px; }
    @keyframes cursor-blink {
        0% {
        opacity: 0;
        }
    }
}
/* firefox chat textbox css end here */
.messagewrappser .messagField.disable {    background-color: #f3f3f3;cursor: no-drop;}
.messagewrappser .messagField div { background-color: transparent !important; color: #333; width: 100% !important; margin: 0px !important; -webkit-user-select: text; -moz-user-select: text; -ms-user-select: text; user-select: text; } 
.messagewrappser .messagField p { background-color: transparent !important; color: #333; width: 100% !important; } 
.messagewrappser .messagField::-webkit-scrollbar { width: 5px; } 
.messagewrappser .messagField::-webkit-scrollbar-track { box-shadow: none; } 
.messagewrappser .messagField::-webkit-scrollbar-thumb { background-color: darkgrey; border-radius: 10px; } 
.messagewrappser .messagField {
    float: left;
    width: 100%;
    min-height: 140px;
    padding: 7px 45px 7px 12px;
    font-size: 15px;
    appearance: none;
    border-radius: 0px;
    border: 1px solid #cdcdcd;
    outline: none;
    line-height: normal;
    box-shadow: none !important;
    overflow: auto; user-select: text; white-space: pre-wrap; word-break: break-word;
    scrollbar-width: thin; scrollbar-color: darkgrey;caret-color: #014087;
}
.messagewrappser .messagField.messagFieldRadius { border-radius: 8px; } 
.messagewrappser .messagField[contenteditable][placeholder]:empty:before { content: "Type here…"; position: absolute; color: #999FB9; font-size: 14px; background-color: transparent; cursor: text; } 
.messageFieldWraps .messagSetemojifiles { float: right; width: auto; position: absolute; right: 13px; bottom: 10px; display: flex; } 
.messageFieldWraps .messagField br { width: 100%; float: left; display: block; position: relative; } 

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* custom emoji css start here */
.sendMessgwraps {
    float: left;
    width: 100%;
    position: relative;
}
.sendMessgwraps .emojiUplodfilewraps {
    float: left;
    width: 45px;
    height: 100%;
    padding: 10px 5px;
    position: absolute;
    border: 1px solid #cdcdcd;
    background-color: #e8eaf7;
    right: 0px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}
.headerDashboard .emojiTodoStyle {width: 16px;height: 16px;}
.dashboardmain .emojiTodoStyle {width: 19px;height: 19px;}
.emojiTodoStyle { 
    float: none; 
    width: 26px; 
    height: 26px; 
    margin: 0px 3px 0px 0px; 
    display: inline-block; 
    background-size: contain; 
    background-repeat: no-repeat; 
    background-position: center; 
    vertical-align: middle; 
    font-size: 17px;
    padding: 2px 0px 0px 0px;
    color: transparent;
} 
.messagField .emojiTodoStyle {
    vertical-align: middle; 
    font-size: 17px;
    padding: 2px 0px 0px 0px;
    color: transparent !important;
    display: inline !important;
}
.customemojipicker {
    float: left;
    width: 370px;
    top: 10px;
    bottom: auto;
    background: #EFF1F9 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 14px 3px #eee;
    position: absolute;
    right: 55px;
    margin: 0 auto;
    z-index: 9;
}
.sendMessgwraps .emojiUplodfilewraps .emojibtn {
    float: left;
    width: 100%;
    margin-bottom: 13px;
    cursor: pointer;
}
.sendMessgwraps .emojiUplodfilewraps .emojibtn img {
    width: 19px;
    max-width: 19px;
    margin: 0 auto;
    float: none;
    display: table;
}
.sendMessgwraps .emojiUplodfilewraps .fileuploadbtn {
    float: left;
    width: 100%;
    cursor: pointer; 
    position: relative;
    overflow: hidden;
    text-align: center;
    display: flex;
    margin-left: 0px !important;
}
.sendMessgwraps .emojiUplodfilewraps .fileuploadbtn img {
    width: 25px;
    max-width: 25px;
    margin: 0 auto;
    float: left;
    display: table;
}
.filesuploadewrapps {
    float: left;
    width: 100%;
    position: relative;
}
.filesuploded {
    float: left;
    width: auto;
    display: flex;
    padding: 10px 13px;
    background-color: #eee;
    border-radius: 5px;
    position: relative;
}
.filesuploded .iconfile{
    float: left;
    width: 35px;
    height: 39px;
    overflow: hidden;
    border-radius: 6px 14px 6px 6px;
    background-color: #014087;
    color: #fff;
    text-align: center;
    margin: 0 10px 0 0;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.filesuploded .imagepreviewBox {
    float: left;
    width: 35px;
    height: 35px;
    margin: 1px 5px 0px 0px;
    border-radius: 4px;
}
.filesuploded .imagepreviewBox img {
    object-fit: cover;
    object-position: center;
}
.filesuploded .filedetails {
    float: left;
    width: auto;
} 
.filesuploded .filedetails h4 {
    margin: 2px 0px 1px;
    line-height: normal;
} 
.filesuploded .filedetails span {
    font-size: 12px;
    color: #919191;
} 
.filesuploded .closechipsuser {
    right: -6px;
    top: -3px;
    cursor: pointer;
}
.btngrouptbsemoji {display: flex;background: #eee;}
.btngrouptbsemoji .btn {width: 15%;outline: none;border-radius: 0px;border: 0px;padding: 10px 10px;font-size: 14px;box-shadow: none !important;position: relative;height: 50px;background-position: center;background-repeat: no-repeat;background-size: 22px;background-color: transparent !important;filter: invert(33%) sepia(8%) saturate(13%) hue-rotate(144deg) brightness(97%) contrast(87%);}
.btngrouptbsemoji .btn.btn-primary {background-color: transparent !important;filter: invert(18%) sepia(63%) saturate(2053%) hue-rotate(195deg) brightness(97%) contrast(106%);}
.btngrouptbsemoji .btn:hover {filter: invert(18%) sepia(63%) saturate(2053%) hue-rotate(195deg) brightness(97%) contrast(106%);}
.btngrouptbsemoji .btn.btn-primary::after {content: "";float: left;width: 100%;height: 3px;background: #014087 !important;filter: invert(18%) sepia(63%) saturate(2053%) hue-rotate(195deg) brightness(97%) contrast(106%);position: absolute;left: 0;bottom: 0;}
.btngrouptbsemoji .btn img {width: 25px;margin: 0px auto 5px;display: table;float: none;}
.customemojipicker ul {float: left;width: 100%;margin: 0px;padding: 0px;position: relative;height: 273px;bottom: 0;overflow: auto;justify-content: space-around;background-color: #fff;scrollbar-width: thin; scrollbar-color: darkgrey;caret-color: #014087; } 
.customemojipicker ul::-webkit-scrollbar { width: 5px; } 
.customemojipicker ul::-webkit-scrollbar-track { box-shadow: none; } 
.customemojipicker ul::-webkit-scrollbar-thumb { background-color: darkgrey; border-radius: 10px; } 
.customemojipicker ul li {float: left;width: auto;list-style: none;cursor: pointer;margin: 5px 3px;padding: 6px;border-radius: 6px;}
.customemojipicker ul li:hover {background: #ccc;}
.customemojipicker ul li img {width: 32px;margin: 0 auto;float: none;display: table;}
.emojiTodoEditorStyle { float: none;width: 17px;height: 17px;margin: 0px 3px 0px 0px;display: inline;background-size: contain;background-repeat: no-repeat;background-position: center;vertical-align: middle;font-size: 17px;padding: 2px 0px 0px 0px;color: transparent;} 
.emojiTodoFlagsEditorStyle {float: none;width: 26px;height: 26px;margin: 0px 3px 0px 0px;display: inline;background-size: contain;background-repeat: no-repeat;background-position: center;vertical-align: middle;font-size: 20px;padding: 2px 5px;color: transparent;} 
.searchBoxEmoji {float: left;width: 100%;position: relative;}
.searchBoxEmoji input {width: 100%;background: #fff;border: 0px;padding: 12px 10px 12px 37px;font-size: 13px;box-shadow: none;outline: none;background-image: url(../img/searchBox.png);background-size: 16px;background-repeat: no-repeat;background-position: 16px center;}
.emojiGroupTitle {float: left;width: 100%;margin: 10px 0px 2px;padding: 0px 10px;font-size: 14px;color: #9a9999;font-family: 'Inter Extra Light BETA';letter-spacing: 0.7px;}
.emojiwrappers {float: left;width: 100%;position: relative;}
.emojiIconspicks {
    width: 32px !important;
    margin: 0 auto;
    float: none !important;
    display: flex;
    height: 32px !important;
    justify-content: center;
    align-items: center;
    font-size: 24px;
}
.emptyImageswraps {float: left;width: 100%;height: 272px;display: flex;justify-content: center;align-items: center;flex-direction: column;text-align: center;}
.emptyImageswraps img {width: 68px;margin: 0 auto 10px;}
.emptyImageswraps h4 {font-size: 16px;color: #6e6e6e;}
.tabsIcons {float: left;width: 24px;height: 24px;background-color: #000000;mask-position: center;-webkit-mask-position: center;mask-repeat: no-repeat;-webkit-mask-repeat: no-repeat;mask-size: contain;-webkit-mask-size: contain;position: absolute;left: 0;top: 2px;}
.chatnotiftbtn .btn.active .tabsIcons {background-color: #014087;}
.chatnotiftbtn {float: left;width: 100%;border-bottom: 1px solid #ddd;position: relative;margin: 0 0 10px}
.tabsIcons.User_announcement{-webkit-mask-image: url("../img/announcement.png"); mask-image: url("../img/announcement.png");} 
.tabsIcons.Notification_management{-webkit-mask-image: url("../img/Notification.png"); mask-image: url("../img/Notification.png");} 

.tabsIcons.overview_icons{-webkit-mask-image: url("../img/overview.png"); mask-image: url("../img/overview.png");} 
.tabsIcons.payment_icons{-webkit-mask-image: url("../img/payment.png"); mask-image: url("../img/payment.png");} 
/* custom emoji css end here */

/* upload file box css start here */
.uploadfilewrapper {
    float: left;
    width: 290px;
    position: absolute;
    right: 50px;
    top: 20px;
}
.uploadfilewrapper ul {
    float: left;
    width: 100%;
    padding: 0px;
    margin: 0px;
}
.uploadfilewrapper ul li .uploadfilesset .filloaderwrapper { height: 100%; width: 100%; position: absolute; z-index: 1; background: rgba(0,0,0,0.5); border-radius: 25px; display: flex; align-items: center; justify-content: center; overflow: hidden; } 
.uploadfilewrapper ul li .uploadfilesset .filloaderwrapper img{ height: 100%; width: 100%; filter: invert(99%) sepia(99%) saturate(2%) hue-rotate(271deg) brightness(104%) contrast(101%); } 
.uploadfilewrapper ul li {
    float: left;
    width: 20%;
    position: relative;
    list-style: none;
    margin: 0px 7px;
}
.uploadfilewrapper ul li .Tooltip-Wrapper {
    float: left;
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
}
.uploadfilewrapper ul li .uploadfilesset {
    float: left;
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
    overflow: hidden;
}
.uploadfilewrapper ul li .uploadfilesset img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}
.uploadfilewrapper ul li .uploadfilesset input {
    float: left;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    visibility: hidden;
}
/* upload file box css end here */

/* devices css start here */
.deviceswrapper {
    float: left;
    width: 100%;
    position: relative;
}
.deviceswrapper li {
    float: left;
    width: 100%;
    position: relative;
    list-style: none;
    padding: 8px 0px;
    display: flex;
}
.deviceswrapper li:hover {
    background-color: #fafafa;
}
.deviceswrapper li .BrowserImage {
    float: left;
    width: auto;
    margin: 0 14px 0 0px;
    position: relative;
}
.deviceswrapper li .BrowserImage img {
    width: 43px;
    margin: 2px 0 0;
}
.deviceswrapper li .Tooltip-Wrapper {
    position: absolute;
    right: 5px;
    top: 17px;
}
.Deactivatebtn {
    border: 0px;
    background: transparent;  
}
.Deactivatebtn img {
    width: 25px;
}
.deviceswrapper li .browserDetails {
    float: left;
    width: auto;
}
.deviceswrapper li .browserDetails h4 {
    font-family: 'Inter Semi Bold';
    font-size: 14px;
    letter-spacing: 0.3px;
    margin: 2px 0 1px;
}
.deviceswrapper li .browserDetails span {font-size: 12px;}
.deviceswrapper li::after {
    content: "";
    float: left;
    width: calc(100% - 62px);
    height: 1px;
    background-color: #f3f3f3;
    position: absolute;
    right: 0;
    bottom: 0;
}
/* devices css end here */

/* notification add css start here */
.tablesbtn {
    border: 0px;
    background: transparent;
}
.tablesbtn img {
    width: 18px;
}
.dashboardmain .page-wrapper .page-body .card .card-table table .Tooltip-Tip {
    line-height: 24px;
    width: 95px;
}
.messagewrappser .messagField div.emojiTodoStyle {
    width: 23px !important;
    margin: 0px 3px 0px 0px !important;
    float: none !important;
    height: 23px !important;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.headerDashboard .navbar-nav .nav-item.active .nav-link .nav-link-icon .icon {
    filter: invert(17%) sepia(96%) saturate(1565%) hue-rotate(196deg) brightness(97%) contrast(104%);
}
.notifctionbtnsearch {
    float: right;
    width: auto;
    position: absolute;
    right: 0;
    top: 0;
}
.notifctionbtnsearch select {padding: 5px 17px 5px 9px;font-size: 12px;appearance: none;border-radius: 0px;border: 1px solid #cdcdcd;outline: none;background-image: url("../img/caret-down.png");background-repeat: no-repeat;background-size: 8px;background-position: 97% 11px;cursor: pointer;margin: 0px 10px 0px 0px !important;}
.notifctionbtnsearch .searchBoxsml {float: left;padding: 5px 26px 5px 9px;margin: 0px 10px 0px 0;font-size: 12px;border-radius: 0px;border: 1px solid #cdcdcd;outline: none;background-image: url("../img/searchBox.png");background-size: 14px;background-position: 96% center;background-repeat: no-repeat;}
.addnotifcation {
    border: 0px;
    background: #014087;
    font-size: 12px;
    color: #fff;
    padding: 5px 7px;
    box-sizing: border-box;
}
.addnotifcation img {
    width: 19px;
    margin: 0 3px 0 0;
    filter: invert(100%) sepia(100%) saturate(289%) hue-rotate(181deg) brightness(108%) contrast(101%);
}
/* notification add css end here */

/* common popup css start here */
.commonpopup {
    float: left;
    width: 440px;
    background-color: #fff;
    position: fixed;
    left: 50%;
    top: 35%;
    transform: translate(-50%, -50%);
    margin: 0;
    padding: 0px !important;
    box-shadow: 0px 0px 21px -1px #6a6a6a;
    z-index: 9999;
}
.commonpopup .contentEdtblewrapper .emojiTodoStyle {
    width: 23px !important;
    height: 23px !important;
    vertical-align: middle;
    font-size: 17px;
    padding: 2px 0px 0px 0px;
    color: transparent;
}
.commonpopup .commonpopupheader {
    float: left;
    width: 100%;
    padding: 13px 20px;
    display: flex;
    align-content: space-between;
    justify-content: space-between;
    position: relative;
    background-color: #014087;
    color: #fff;
}
.commonpopup .commonpopupheader h4 {
    float: left;
    width: auto;
    margin: 0px;
    font-size: 14px;
    font-family: 'Inter Semi Bold';
}
.commonpopup .commonpopupheader button {
    padding: 0px;
    margin: 0px;
    background: transparent;
    border: 0px;
    width: 22px;
    float: right;
}
.commonpopup .commonpopupheader button img {
    filter: invert(100%) sepia(6%) saturate(7463%) hue-rotate(290deg) brightness(114%) contrast(112%);
}
.commonpopup .commonpopupBox {
    float: left;
    width: 100%;
    padding: 20px 20px;
    position: relative;
}
.commonpopup .commonpopupBox .messagField {
    float: left;
    width: 100%;
    height: 140px;
    max-height: 140px;
    padding: 10px 35px 10px 12px;
    font-size: 15px;
    appearance: none;
    border-radius: 0px;
    border: 1px solid #cdcdcd;
    outline: none;
    line-height: normal;
    box-shadow: none !important;
    overflow: auto;
    -webkit-user-select: text;
    user-select: text;
    white-space: pre-wrap;
    word-break: break-word;
}
.commonsbtn {
    padding: 9px 12px;
    line-height: normal;
    box-sizing: border-box;
    border: 0px;
    background-color: #014087;
    color: #fff;
    font-size: 14px;
    margin: 0 13px 0 0;
}
.commoncancelbtn {
    padding: 9px 12px;
    line-height: normal;
    box-sizing: border-box;
    border: 0px;
    font-size: 14px;
    margin: 0 13px 0 0;
}
.commonpopup .commonpopupBox .emojiselectcmn {
    border: 0px;
    background: transparent;
    padding: 0px;
    width: 20px;
    position: absolute;
    right: 10px;
    top: 10px;
}
.commonpopup .commonpopupBox .customemojipicker {
    right: 5px;
    top: 38px;
    width: 310px;
    box-shadow: 0px 0px 22px -3px #6d6d6d;
}
.commonpopup .commonpopupBox .customemojipicker .btngrouptbsemoji .btn {
    background-size: 20px;
    height: 44px !important;
}
.contentEdtblewrapper {
    float: left;
    width: 100%;
    position: relative;
}
.addgametextfield {
    float: left;
    width: 100%;
    height: 39px;
    max-height: 140px;
    padding: 8px 12px 8px 12px;
    font-size: 13px;
    appearance: none;
    border-radius: 0px;
    border: 1px solid #cdcdcd;
    margin: 12px 0 0;
    outline: none !important;
}
.commonpopup.deletcomonpop {
    text-align: center;
    width: 355px;
}
.commonpopup.previewcustomerdetails {
    width: 640px;
}
.commonpopup.previewcustomerdetails .commonpopupBox .chatnotiftbtn .btn .tabsIcons {
    width: 19px;
    height: 19px;
}
.customerscolswraps {
    float: left;
    width: 100%;
}
.carddetailswraps {
    float: left;
    width: 100%;
    padding: 0px 24px 10px 24px;
    position: relative;
}
.dashboardmain .page-wrapper .page-body .customerscolswraps .card .card-table table tbody tr:hover {
    background-color: #f6f6f6;
    cursor: pointer;
}
.dashboardmain .page-wrapper .page-body .customerscolswraps .card .card-table table tbody tr.active {
    background-color: #f6f6f6;
}
.invoicelistaddedcustomer .page-wrapper .page-body, .invoicelistaddedcustomer .page-wrapper .page-body .container-xl {
    padding: 0px !important;
}
.invoicelistaddedcustomer .page-wrapper .page-body {
    min-height: fit-content;
}
.invoicelistaddedcustomer {
    float: left;
    width: 100%;
    position: relative;
}
.invoicelistaddedcustomer .footer.footer_section {
    display: none;
}
.paginationCustomcustomers {
    margin: 0px 0px 15px !important;
}
.cardheaders {
    float: left;
    width: 100%;
    position: relative;
}
.cardheaders ul {
    float: left;
    width: 100%;
    margin: 0;
    padding: 0px;
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
}
.cardheaders ul li {
    float: left;
    width: 48%;
    font-size: 14px;
    margin-bottom: 19px;
    list-style: none;
    border-bottom: 1px solid #eee;
    padding: 0 0 10px;
    flex-basis: 48%;
}
.cardheaders ul li label {
    float: left;
    width: 100%;
    font-family: 'Inter Medium';
    color: #A2A7B1;
    font-size: 12px;
    margin: 0 0 3px;
    letter-spacing: 0.4px;
}
.commonpopup.previewcustomerdetails .commonpopupBox .chatnotiftbtn .btn .tabsIcons.overview_icons {
    width: 17px;
    height: 17px;
    margin: 3px 0 0 0;
} 
.tabs-content {
    float: left;
    width: 100%;
    position: relative;
}
.commonpopup.deletcomonpop .Deletemodal .deleteimage{
    width: 55px;
    margin: 5px 0 10px;
}
.custmeridhighlight {
    color: #014087;
    cursor: pointer;
    font-family: 'Inter Medium';
}
.commonpopup .commonpopupBox .messagField[contenteditable][placeholder]:empty:before { content: "Type here…"; position: absolute; color: #999FB9; font-size: 14px; background-color: transparent; cursor: text; } 
.contentEdtblewrapper.gameplaceholder .messagField[contenteditable][placeholder]:empty:before { content: "Enter Game Name.." !important;} 
.commonpopup .commonpopupfooter {
    float: left;
    width: 100%;
    padding: 15px 20px;
    position: relative;
    border-top: 1px solid #ddd;
}
.modalBckground {
    float: left;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0,0,0,0.4);
    z-index: 999;
    margin: 0px;
}
.commonpopup .Deletemodal {
    float: left;
    width: 100%;
    padding: 15px 20px 0px;
    position: relative;
}
.commonpopup .Deletemodal h4 {
    float: left;
    width: 100%;
    margin: 3px 0px 7px;
    font-size: 18px;
    font-family: 'Inter Semi Bold';
}
.commonpopup .Deletemodal p {
    float: left;
    width: 100%;
    font-size: 15px;
}
.searchlistWrapperlist.notificatilistWrapperlist ul li {
    display: flex;
}
.searchlistWrapperlist.notificatilistWrapperlist ul li .notificatinIconlist {
    float: left;
    width: 20px;
    margin: 6px 11px 0px 0px;
}
/* common popup css end here */

/* preview popup common start here */
.tablesbtn.previewIcon img {
    width: 26px;
    max-width: fit-content;
}
.invoicpreviewpopup {
    float: left;
    width: 470px;
    background-color: #fff;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    padding: 0px !important;
    box-shadow: 0px 0px 21px -1px #6a6a6a;
    z-index: 9999;
    border-radius: 20px;
    overflow: hidden;
}
.formCustomUi-group {
    float: left;
    width: 100%;
    position: relative;
}
.formCustomUi-group.edit {
    margin-bottom: 11px;
}
.formCustomUi-group label {
    float: left;
    width: auto;
    position: absolute;
    right: 7px;
    top: 1px;
    font-size: 11px;
    padding: 5px 9px;
    box-sizing: border-box;
    line-height: normal;
    border-radius: 10px;
    background-color: #eee;
}
.invoicpreviewpopupheader {
    float: left;
    width: 100%;
    background-color: #1D1D1D;
    color: #fff;
    padding: 15px 30px 21px;
    position: relative;
}
.invoicpreviewpopupheader .invoiceid {
    float: right;
    width: auto;
    text-align: left;
    position: relative;
}
.invoicpreviewpopupheader .invoiceid span {
    color: #8B8B8B;
    font-size: 12px;
    margin: 0px 0 2px;
    display: table;
}
.invoicpreviewpopupheader .invoiceid div {
    font-size: 13px;
}
.invoicpreviewpopupheader h4 {
    float: left;
    width: 100%;
    color: #FFFFFF;
    letter-spacing: 0.3px;
    font-family: 'Inter Medium';
    margin: 9px 0 5px;
    font-size: 15px;
}
.invoicpreviewpopupheader p {
    float: left;
    color: #C1C1C1;
    width: 100%;
    font-size: 12px;
    margin: 0px 0 0px;
}
.invoicpreviewpopupbody {
    float: left;
    width: 100%;
    padding: 15px 30px 21px;
    background-color: #DCE2E8;
    position: relative;
}
.invoicpreviewpopupbody h5 {
    float: left;
    width: 100%;
    color: #8B8B8B;
    letter-spacing: 0.3px;
    font-size: 12px;
}
.invoicpreviewpopupbody ul {
    float: left;
    width: 100%;
    max-height: calc(100vh - 300px);
    overflow: auto;
    padding: 0px;
    margin: 0px;
    position: relative;
}
.invoicpreviewpopupbody ul li {
    float: left;
    width: 100%;
    list-style: none;
    padding: 6px 6px;
    background-color: #fff;
    border-radius: 7px;
    margin-bottom: 8px;
}
.invoicpreviewpopupbody ul li .Descriphead {
    float: left;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #F4EFEF;
    padding: 8px 14px;
    border-radius: 6px;
}
.invoicpreviewpopupbody ul li .Descriphead h4 {
    float: left;
    width: auto;
    margin: 0px;
    color: #1D1D1D;
    font-family: 'Inter Semi Bold';
    letter-spacing: 0.3px;
    font-size: 13px;
}
.invoicpreviewpopupbody ul li .Descriphead div {
    color: #E25E24;
    font-family: 'Inter Semi Bold';
    letter-spacing: 0.3px;
    font-size: 13px;
}
.invoicpreviewpopupbody ul li .discrcolswraps {
    float: left;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    padding: 7px 14px;
    position: relative;
}
.invoicpreviewpopupbody ul li .discrcolswraps .discrcols {
    float: left;
    width: 25%;
    text-align: left;
    color: #1D1D1D;
    display: grid;
    font-size: 15px;
    font-family: 'Inter Semi Bold';
}
.invoicpreviewpopupbody ul li .discrcolswraps .discrcols span {
    float: left;
    width: 100%;
    color: #8B8B8B;
    font-size: 13px;
    margin: 0 0 4px;
    letter-spacing: 0.5px;
    font-family: 'Inter Regular';
}
.totalinvoce {
    float: left;
    width: 100%;
    list-style: none;
    padding: 10px 20px;
    background-color: #fff;
    border-radius: 7px;
    display: grid;
    color: #fff;
    background-image: url("../img/totalammount.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.totalinvoce h4{
    float: left;
    width: 100%;
    color: #91A7FF;
    margin: 5px 0px 4px;
    position: relative;
    font-size: 13px;
}
.totalinvoce h3{
    float: left;
    width: 100%;
    position: relative;
    font-family: 'Inter Semi Bold';
    font-size: 21px;
}
.invoicpreviewpopupheader img {
    position: absolute;
    left: 10px;
    top: 10px;
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(79deg) brightness(102%) contrast(102%);
    width: 18px;
    cursor: pointer;
}
/* preview popup common end here */

.searchlistWrapperlist ul li .userdetails.customersearch {
    width: 100%;
}
.searchlistWrapperlist ul li .userdetails.customersearch h4 div {
    float: left;
    width: auto;
}
.searchlistWrapperlist ul li .userdetails.customersearch h4 span {
    width: auto;
    font-size: 11px;
    margin: 2px 0 0 3px;
    font-weight: 100;
    letter-spacing: 0.6px;
    font-family: 'Inter Light BETA';
    color: #000;
}
.searchlistWrapperlist.customerlistWrapperlist {
    right: 212px;
}
ul.customer_details {
    padding: 0px;
    margin: 0px;
}
ul.customer_details li {
    list-style: none;
    font-size: 13px;
    margin: 0 0 4px;
    display: table;
}
ul.customer_details .Tooltip-Wrapper {
    display: block;
} 